import EmblaCarousel, { EmblaCarouselType } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import ClassNames from "embla-carousel-class-names";

const initSlider = () => {
  const introEl = document.querySelector<HTMLElement>(".js-embla-intro");
  if (!introEl) return;

  const autoplayer = Autoplay({ delay: 5000 });
  const classNames = ClassNames();
  const options = { draggable: false, skipSnaps: false };

  const embla = EmblaCarousel(introEl, options, [autoplayer, classNames]);

  const toggleEmblaReady = (event: any) => {
    // Inactivate transform
    embla.internalEngine().translate.toggleActive(false);

    const isResizeEvent = event === "resize";
    const toggleClass = isResizeEvent ? "remove" : "add";
    introEl.classList[toggleClass]("embla--is-ready");
    isResizeEvent && embla.reInit(options);
  };

  embla.on("init", toggleEmblaReady);
  embla.on("resize", toggleEmblaReady);
  embla.on("reInit", toggleEmblaReady);
};

const handleButtonVisibility = (
  carousel: EmblaCarouselType,
  leftEl: HTMLButtonElement | null,
  rightEl: HTMLButtonElement | null
) => {
  if (!leftEl || !rightEl) return;
  leftEl.disabled = !carousel?.canScrollPrev();
  rightEl.disabled = !carousel?.canScrollNext();
};

export const initCarousels = () => {
  initSlider();

  const galleryEl = document.querySelector<HTMLElement>(".js-embla-gallery");
  if (!galleryEl) return;

  const carousel = EmblaCarousel(galleryEl, {
    align: "start",
  });

  const leftEl = document.querySelector<HTMLButtonElement>(".js-carousel-prev");
  const rightEl =
    document.querySelector<HTMLButtonElement>(".js-carousel-next");

  leftEl?.addEventListener("click", () => carousel.scrollPrev());
  rightEl?.addEventListener("click", () => carousel.scrollNext());

  carousel.on("init", () => handleButtonVisibility(carousel, leftEl, rightEl));
  carousel.on("select", () =>
    handleButtonVisibility(carousel, leftEl, rightEl)
  );
};
