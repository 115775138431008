import * as CookieConsent from "vanilla-cookieconsent";

export const cookieBannerBtn = () => {
  document.addEventListener("click", (e) => {
    const target = <HTMLElement>e.target;

    if (!target.closest(".js-cookie-link")) return;

    e.preventDefault();
    CookieConsent.show(true);
  });
};

export const initCookieBanner = () => {
  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: "box",
        position: "bottom right",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        position: "right",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      functionality: {},
    },
    language: {
      default: document.documentElement.lang,
      translations: {
        de: {
          consentModal: {
            title: "Cookie Richtlinien",
            description:
              "Unsere Website verwendet Cookies, um um dein Nutzererlebnis zu verbessern. Per Klick auf „Zustimmen“ erklärst du dich mit der Verwendung von Cookies einverstanden.",
            acceptAllBtn: "Zustimmen",
            acceptNecessaryBtn: "Ablehnen",
            showPreferencesBtn: "Einstellungen verwalten",
            footer: '<a href="/de/privacy">Datenschutz & Privacy</a>',
          },
          preferencesModal: {
            title: "Präferenzen für die Zustimmung",
            acceptAllBtn: "Zustimmen",
            acceptNecessaryBtn: "Ablehnen",
            savePreferencesBtn: "Einstellungen speichern",
            closeIconLabel: "Modal schließen",
            serviceCounterLabel: "Dienstleistungen",
            sections: [
              {
                title: "Verwendung von Cookies",
                description: "",
              },
              {
                title:
                  'Notwendige Cookies <span class="pm__badge">Immer Aktiviert</span>',
                description:
                  "Diese Cookies sind notwendig um die grundlegende Funktionalität unserer Website zu garantieren und könenn somit nicht deaktiviert werden",
                linkedCategory: "necessary",
              },
            ],
          },
        },
        it: {
          consentModal: {
            title: "Gestione dei Cookie",
            description:
              'Il nostro sito web utilizza i cookie per migliorare la tua esperienza utente. Cliccando su "Accetto" acconsenti all uso dei cookie.',
            acceptAllBtn: "Accettare",
            acceptNecessaryBtn: "Rifiutare",
            showPreferencesBtn: "Gestisci le preferenze individuali",
            footer: '<a href="/it/intimita">Privacy</a>',
          },
          preferencesModal: {
            title: "Preferenze di consenso",
            acceptAllBtn: "Accettare",
            acceptNecessaryBtn: "Rifiutare",
            savePreferencesBtn: "Salva le impostazioni",
            closeIconLabel: "Chiudi modale",
            serviceCounterLabel: "Servizi",
            sections: [
              {
                title: "Utilizzo dei cookie",
                description: "",
              },
              {
                title:
                  'Cookie necessari <span class="pm__badge">Sempre abilitati</span>',
                description:
                  "Questi cookie sono necessari per garantire le funzionalità di base del nostro sito web e pertanto non possono essere disattivati",
                linkedCategory: "necessary",
              },
            ],
          },
        },
        en: {
          consentModal: {
            title: "Cookie Policy",
            description:
              "Our website uses cookies to improve your user experience. By clicking “Agree” you agree to the use of cookies.",
            acceptAllBtn: "Accept",
            acceptNecessaryBtn: "Reject",
            showPreferencesBtn: "Manage Individual preferences",
            footer: '<a href="/en/privacy">Privacy</a>',
          },
          preferencesModal: {
            title: "Consent preferences",
            acceptAllBtn: "Accept",
            acceptNecessaryBtn: "Reject",
            savePreferencesBtn: "Save Settings",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Services",
            sections: [
              {
                title: "Use of cookies",
                description: "",
              },
              {
                title:
                  'Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  "These cookies are necessary to guarantee the basic functionality of our website and therefore cannot be deactivated",
                linkedCategory: "necessary",
              },
            ],
          },
        },
      },
    },
  });
};
