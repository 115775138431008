const handleScroll = () => {
  const header = document.querySelector<HTMLElement>(".js-header");

  const scrollTopOffset = window.scrollY || document.documentElement.scrollTop;

  scrollTopOffset
    ? header?.classList.add("dark")
    : header?.classList.remove("dark");
};

export const initScroll = () => {
  document.addEventListener("scroll", handleScroll);
};

export const initScrollHandling = () => {
  const sections = document.querySelectorAll("section");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const currentActive = document.querySelector(".js-menu-link.active");
          currentActive?.classList.remove("active");

          const currentLink = document.querySelector(
            `[href="/${document.documentElement.lang}#${entry.target.id}"]`
          );
          currentLink?.classList.add("active");
        }
      });
    },
    {
      threshold: 0.55,
    }
  );

  sections.forEach((section) => {
    observer.observe(section);
  });
};
