import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import "../css/entry.css";
import { initCarousels } from "./components/carousel";
import { cookieBannerBtn, initCookieBanner } from "./components/cookie-banner";
import { initLightbox } from "./components/lightbox";
import { initNav } from "./components/menu";
import { initModal } from "./components/modal";
import { initScroll, initScrollHandling } from "./components/scroll";

initCookieBanner();
cookieBannerBtn();
initCarousels();
initNav();
initLightbox();
initScroll();
initScrollHandling();
initModal();
